import React, { useEffect, useRef } from "react";

// 3rd parties
import { useSelector } from "react-redux";

import L from "leaflet";
import "leaflet-control-geocoder";
import "leaflet.fullscreen";

// owner
import useCustomMap from "./useCustomMap";
import { LocationIQ } from "./Geocoders/LocationIQ";
import { LayerUnwired } from "./Layers/LayerUnwired";
import { LayerNameUtil } from "./utils/LayerNameUtil";

import { CurrentLocation } from "./Marker/CurrentLocation";
import { ControlOverview } from "./Marker/ControlOverview";

import CombinedShapeIcon from "@images/map/combined-shape.png";

/**
 * A DateRangeWidget component for selecting date ranges.
 *
 * @param {string} width - width of map.
 * @param {string} height - height of map.
 * @param {moment} value.start - The start of the date range.
 * @param {moment} value.end - The end of the date range.
 *
 * @param {object} options - Override default display modes. (default: {day: true, week: true, month: true, year: true, custom: true, all: false, last90d: false})
 * @param {string} options.baseLayerDefault - the base layer default of map. value: Satellite  || stress.
 * @param {array} options.center - a center of map. value: [50, 10]
 * @param {number} options.zoom - a zoom of map. value: 2.
 * @param {string} options.zoomPosition - zoom control position. value: 'topright'.
 * @param {boolean} options.hideCurrentLocation - a flag to hide current location button.
 * @param {boolean} options.hideOverview - a flag to hide overview button.
 * @param {boolean} options.searchBox - a flag to show search box.
 * @param {boolean} options.hideFullScreen - a flag to hide fullScreen button.
 *
 * @param {useRef} mapRef - a ref hook.
 *
 * @param {function} onMapLoaded - a map loaded callback
 *
 * @param {ReacChildren} children - children component.
 *
 * @returns {JSX.Element} The rendered DateRangeWidget component.
 *
 * @example
 * // Usage with custom mode and hidden buttons:
 * <CommonMapReact
 *  mapRef={map}
 *  width="100%"
 *  height={"100vh"}
 *  options={{
          hideCurrentLocation: false,
          hideOverview: false,
          searchBox: true,
          showGeozoneLayer: true,
          showNetworkLayer: true,
        }}
    onMapLoaded={() => {
      setMapLoaded(true);
    }}
 * >
 *  <div>Cluster</div>
 *  <div>Contol layers</div>
 * </CommonMapReact>
 */
const CommonMapReact = ({
  width = "100%",
  height,
  options,
  mapRef,
  onMapLoaded,
  children,
}) => {
  const language = useSelector((state) => state.language);
  const loginInfo = useSelector((state) => state.user);

  // const [mapLoaded, setMapLoaded] = useState(true);

  let domMap = useRef();
  const CustomMap = useCustomMap();
  const key = "pk.6ed9cebcc44f553e3a45117cb9cb2ce4";

  useEffect(() => {
    var proxy = loginInfo.mapProxyEnabled;

    var streets = LayerUnwired({ proxy: proxy, scheme: "streets" });
    streets.name = language.street_key;

    var satellite = LayerUnwired({ proxy: proxy, scheme: "satellite" });
    satellite.name = LayerNameUtil.Satellite;

    let _default = streets;

    if (options.baseLayerDefault === LayerNameUtil.Satellite) {
      _default = satellite;
    }

    let _center = options.center || [50, 10],
      _zoom = options.zoom || 2;

    mapRef.current = new CustomMap(domMap);

    mapRef.current.setView(_center, _zoom).addLayer(_default);

    mapRef.current.baseLayers = [];

    let baseLayers = {};
    baseLayers[LayerNameUtil.Street] = streets;
    baseLayers[LayerNameUtil.Satellite] = satellite;

    mapRef.current.zoomControl.setPosition(options.zoomPosition || "topright");

    mapRef.current.layerControl = L.control.layers(baseLayers, {});
    mapRef.current.baseLayers.push(streets);
    mapRef.current.baseLayers.push(satellite);

    !options.hideCurrentLocation &&
      CurrentLocation({ title: language.current_location_key }).addTo(
        mapRef.current
      );

    !options.hideOverview &&
      ControlOverview({ title: language.zoom_see_all_assets_key }).addTo(
        mapRef.current
      );

    mapRef.current.addGeozoneLayer();
    mapRef.current.addAssetLayer();
    mapRef.current.addInfraLayer();
    mapRef.current.addNetworkLayer();
    // mapRef.current.addCustomLayers();
    options.searchBox && initSearchBox();
    !options.hideFullScreen && initFullScreen();

    L.control.scale({ imperial: false }).addTo(mapRef.current);
    mapRef.current.on("baselayerchange", function (e) {
      window.defaultMap = e.name;
    });

    mapRef.current.setMaxBounds([
      [-90, -180],
      [90, 180],
    ]);

    // setMapLoaded(true);
    onMapLoaded && onMapLoaded();
    return () => {
      // Cleanup when component is unmounted
      if (mapRef.current) {
        // Destroy map instance
        mapRef.current.remove();
      }
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (mapLoaded) {
  //     onMapLoaded && onMapLoaded();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [mapLoaded]);

  const initSearchBox = () => {
    let searchPosition = null;
    L.Control.geocoder({
      position: "topleft",
      collapsed: false,
      defaultMarkGeocode: false,
      geocoder: LocationIQ(key),
      placeholder: language.search_for_a_location_key,
    })
      .on("markgeocode", function (e) {
        let bbox = e.geocode.bbox;

        let position;
        if (bbox.getSouthEast().distanceTo(bbox.getNorthWest()) === 0) {
          mapRef.current.setView(bbox.getSouthEast(), 21);
          position = bbox.getSouthEast();
        } else {
          let bounds = L.latLngBounds([
            bbox.getSouthEast(),
            bbox.getNorthWest(),
          ]);
          mapRef.current.fitBounds(bounds);
          position = mapRef.current.getCenter();
        }
        searchPosition && searchPosition.remove();

        searchPosition = L.marker(position, {
          title: language.search_location_key,
          icon: L.icon({
            iconUrl: CombinedShapeIcon,
            iconAnchor: [11, 10],
            iconSize: [22, 20],
          }),
        }).addTo(mapRef.current);
      })
      .addTo(mapRef.current);
  };

  const initFullScreen = () => {
    L.control
      .fullscreen({
        position: "bottomright", // change the position of the button can be topleft, topright, bottomright or bottomleft, defaut topleft
        title: language.show_me_the_fullscreen_key, // change the title of the button, default Full Screen
        titleCancel: language.exit_fullscreen_mode_key, // change the title of the button when fullscreen is on, default Exit Full Screen
        content: null, // change the content of the button, can be HTML, default null
        forceSeparateButton: true, // force seperate button to detach from zoom buttons, default false
        forcePseudoFullscreen: false, // force use of pseudo full screen even if full screen API is available, default false
        fullscreenElement: false, // Dom element to render in full screen, false by default, fallback to mapRef.current._container
      })
      .addTo(mapRef.current);
  };

  return (
    <div>
      <div
        ref={(el) => {
          domMap = el;
        }}
        className="p-2 snt-map"
        style={{ width: width, height: height }}
      >
        {React.Children.map(children, (child, i) => {
          if (mapRef.current && child && child.props) {
            return React.createElement(child.type, {
              ...{
                ...child.props,
                map: mapRef.current,
              },
              key: i,
            });
          }

          return child;
        })}
      </div>
    </div>
  );
};
export default CommonMapReact;
