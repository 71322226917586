import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Container, Nav, NavDropdown } from "react-bootstrap";
import { useWindowSize } from "react-use";
import { useRoutes } from "./useRoutes";

const SntTabGroup = ({ tabGroupInfo, children }) => {
  const delay = useRef(null);
  const tabWidget = useRef(null);
  const container = useRef(null);
  const [hiddenItems, setHiddenItems] = useState([]);
  const [visibleItems, setVisibleItems] = useState([]);

  const { routeParams, updateRoute } = useRoutes(tabGroupInfo);

  function convertToTabFormat(arr) {
    return arr.map((c, i) => ({
      index: i,
      id: c.props.id,
      label: c.props.label,
      alwaysHidden: c.props.alwaysHidden,
      children: c.props.children,
    }));
  }
  const filteredChildren = useRef(convertToTabFormat(children));

  const getSelectedIndex = (i) => {
    let selectedTab = filteredChildren.current.find((c) => c.id === i);
    return selectedTab ? selectedTab.index : -1;
  };

  const [selectedIndex, setSelectedIndex] = useState(() => {
    if (tabGroupInfo.tabActive) {
      return getSelectedIndex(tabGroupInfo.tabActive);
    }
    return 0;
  });

  useEffect(() => {
    setSelectedIndex(getSelectedIndex(routeParams.tabActive));
  }, [routeParams.tabActive]);

  useEffect(() => {
    let tabActive = filteredChildren.current[selectedIndex];

    tabActive && updateRoute({ tabActive: tabActive.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  const hiddenItemRender = useMemo(() => {
    return hiddenItems.map((item, idx) => (
      <NavDropdown.Item
        key={idx}
        onClick={(e) => {
          e.preventDefault();
          setSelectedIndex(item.index);
        }}
      >
        {item.label}
      </NavDropdown.Item>
    ));
  }, [hiddenItems]);

  const visibleItemRender = useMemo(() => {
    return visibleItems.map((item) => (
      <a
        key={item.id}
        className={
          selectedIndex === item.index ? "nav-link active" : "nav-link"
        }
        onClick={(e) => {
          e.preventDefault();
          setSelectedIndex(item.index);
        }}
        href=" "
      >
        {item.label}
      </a>
    ));
  }, [visibleItems, selectedIndex]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounce = useCallback(
    _.debounce(() => {
      if (!tabWidget.current) return;
      setVisibleItems(filteredChildren.current);
      let currentColor = tabWidget.current.style.color;
      tabWidget.current.style.color = "white";
      clearTimeout(delay.current);
      delay.current = setTimeout(() => {
        if (!tabWidget.current) return;
        let tabWidgetChildren = Array.from(tabWidget.current.children);
        let currentWidth = 50;
        let tempVisibleItems = [];
        let tempHiddenItems = [];

        for (let i = 0; i < tabWidgetChildren.length; i++) {
          currentWidth += tabWidgetChildren[i].clientWidth;
          if (
            container.current.clientWidth - currentWidth >= 0 &&
            !filteredChildren.current[i].alwaysHidden
          ) {
            tempVisibleItems.push(filteredChildren.current[i]);
          } else {
            tempHiddenItems.push(filteredChildren.current[i]);
          }
        }

        // remove a item from visible items to give the space to MORE element
        if (tempHiddenItems.length > 0) {
          let lastItems = tempVisibleItems.pop();
          if (lastItems) {
            tempHiddenItems.unshift(lastItems);
          }
        }
        tabWidget.current.style.color = currentColor;
        setVisibleItems(tempVisibleItems);
        setHiddenItems(tempHiddenItems);
      }, 0);
    }, 50),
    []
  );

  const size = useWindowSize();
  useEffect(() => {
    debounce();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size, children]);

  const content = useMemo(() => {
    return (
      <div
        className="tab-pane"
        key={selectedIndex}
        style={{ display: "block" }}
      >
        {children[selectedIndex]
          ? children[selectedIndex].props.children
          : null}
      </div>
    );
  }, [children, selectedIndex]);

  return (
    <>
      <div className="tabs" style={{ position: "relative" }}>
        <Container className="nav-tabs p-0" fluid ref={container}>
          <Nav ref={tabWidget} style={{ width: 10000, height: 43 }}>
            {visibleItemRender}
          </Nav>

          <NavDropdown
            className={
              hiddenItems.filter((item) => item.index === selectedIndex)
                .length > 0
                ? "tabdrop"
                : ""
            }
            title={
              hiddenItems.filter((item) => item.index === selectedIndex)
                .length > 0
                ? hiddenItems.filter((item) => item.index === selectedIndex)[0]
                    .label
                : "More"
            }
            style={{
              backgroundColor: "white",
              zIndex: 1001,
              position: "absolute",
              top: 0,
              right: 0,
              display: hiddenItems.length > 0 ? "block" : "none",
            }}
          >
            {hiddenItemRender}
          </NavDropdown>
        </Container>

        <div className="tab-content">{content}</div>
      </div>
    </>
  );
};

export default SntTabGroup;
