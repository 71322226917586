import axios from "axios";
import config from "./../constants/Config";
import Cookies from "js-cookie";
import qs from "qs";

const options = {
  baseURL: config.API_URL,
};

const instance = axios.create(options);

// Add a request interceptor
instance.interceptors.request.use(
  (requestConfig) => {
    requestConfig.paramsSerializer = (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    };
    if (requestConfig.url.indexOf("rest") !== -1) {
      let csrfName = Cookies.get("csrf_name");
      let csrf = Cookies.get(csrfName);
      if (csrf !== undefined && csrf !== null) {
        requestConfig.headers["X-CSRF-TOKEN"] = csrf;
      }
    }

    if (config.MODE === "development") {
      requestConfig.withCredentials = true;
    }
    return requestConfig;
  },
  (requestError) => {
    return Promise.reject(requestError);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    if (response && response.headers) {
      let headers = response.headers;

      let cacheControl = headers["cache-control"];
      if (
        cacheControl &&
        (cacheControl.includes("no-store") || cacheControl.includes("no-cache"))
      ) {
        var sntVersion = headers["snt-version"] ? headers["snt-version"] : null;
        if (
          sntVersion &&
          sessionStorage.getItem("NEWEST-SNT-VERSION") !== sntVersion
        ) {
          sessionStorage.setItem("NEWEST-SNT-VERSION", sntVersion);
        }
      } else {
        // cache response should be ignored
      }
    }
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      console.log("cancel");
    } else if (401 === error.response.status) {
      let pathname = window.location.pathname;
      if (
        pathname !== "/login" &&
        pathname !== "/login/" &&
        pathname !== "/oauth2/authorize"
      ) {
        if (pathname === "/") {
          window.location.href = `/login`;
        } else {
          window.location.href = `/login?redirect_to=${encodeURIComponent(
            pathname + window.location.search
          )}`;
        }
      }
    }
    return Promise.reject(error);
  }
);

export const apiClient = instance;
