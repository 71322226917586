import { useEffect, useRef, useState } from "react";
import searchUI from "../../../apis/searchUIClient";
import FilterList from "../../../components/AdvancedFilters/FilterList";
import SimpleFilterList from "../../../components/SimpleFilterList/SimpleFilterList";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { FilterLineLoader } from "../../../components/AdvancedFilters/FilterStyle";

const ToolbarHeader = ({
  dataOptions = {},
  children,
  onChange,
  restrictedFilters,
  isAdvanceFilter = true,
  totalText,
  onChangeSimpleFilterList,
  filterList,
  handleTool,
  searchPlaceholder,
  isHideTheUI = false,
  filterListRef,
  disabled = false,
  onClearAllButtonClicked,
  includeDynamicFilter = true,
}) => {
  const optionsSettings = useRef(null);
  const descriptors = useRef(null);

  const [filterListData, setFilterListData] = useState(null);
  const loginInfo = useSelector((state) => state.user);
  const { features, permissionsMap } = loginInfo;

  const loadBasicComponents = () => {
    if (isAdvanceFilter) {
      let _categoriesParam = null;
      if (dataOptions.categories) {
        _categoriesParam = { categories: dataOptions.categories };
      }

      searchUI
        .getDescriptors(dataOptions.queryObjectType, _categoriesParam)
        .then(({ data: _descriptors }) => {
          if (includeDynamicFilter === false) {
            _descriptors.forEach(
              (category) =>
                (category.searchFields = category.searchFields.filter(
                  (filter) => filter.searchType !== "DYNAMIC"
                ))
            );
          }
          descriptors.current = _descriptors;
          setFilterListData({
            queryObjectType: dataOptions.queryObjectType,
            descriptors: _descriptors,
            appliedFilterSettings:
              dataOptions &&
              dataOptions.optionsSettings &&
              dataOptions.optionsSettings.query
                ? dataOptions.optionsSettings.query
                : [],
            filtersVisibleAtInit:
              !features["search_advanced"] &&
              !permissionsMap["SYSTEM_ADMIN_VIEW"]
                ? ["deviceIdentifier", "assetTags", "trackerStatus"]
                : dataOptions.filtersVisibleAtInit,
          });
        })
        .catch((error) => {
          console.log("can not load descriptors: ", error);
        });
    }
  };

  const onFilterChange = (data, action) => {
    onChange &&
      onChange(
        {
          searchQueryType: data.searchQueryType,
          query: data.query,
        },
        action
      );
    optionsSettings.current = { ...optionsSettings.current, query: data.query };
  };

  const onResetFilterData = () => {
    setFilterListData({
      ...filterListData,
      appliedFilterSettings: optionsSettings.current.query.map((query) => {
        delete query.filterValue;
        query.notFilter = false;
        return query;
      }),
    });
    onClearAllButtonClicked && onClearAllButtonClicked();
  };

  useEffect(() => {
    if (isAdvanceFilter) {
      optionsSettings.current = dataOptions.optionsSettings;

      if (
        !filterListData ||
        filterListData.queryObjectType !== dataOptions.queryObjectType
      ) {
        loadBasicComponents();
      } else {
        setFilterListData({
          queryObjectType: dataOptions.queryObjectType,
          descriptors: descriptors.current,
          appliedFilterSettings:
            dataOptions &&
            dataOptions.optionsSettings &&
            dataOptions.optionsSettings.query
              ? dataOptions.optionsSettings.query
              : [],
          filtersVisibleAtInit:
            !features["search_advanced"] && !permissionsMap["SYSTEM_ADMIN_VIEW"]
              ? ["deviceIdentifier", "assetTags", "trackerStatus"]
              : dataOptions.filtersVisibleAtInit,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataOptions]);

  const handleSimpleFilterList = (data) => {
    onChangeSimpleFilterList && onChangeSimpleFilterList(data);
  };

  return (
    <>
      <Row style={{ display: isHideTheUI ? "none" : "" }}>
        <Col>
          {isAdvanceFilter ? (
            filterListData ? (
              <>
                <FilterList
                  restrictedFilters={restrictedFilters}
                  data={filterListData}
                  isVisibleAddFilterBtn={
                    features["alert_search"] || features["search_advanced"]
                  }
                  onChange={onFilterChange}
                  onResetFilterData={onResetFilterData}
                  filterListRef={filterListRef}
                  disabled={disabled}
                  totalElement={<span>{totalText}</span>}
                  filterList={filterList}
                  handleTool={handleTool}
                  deviceCategorySuggestion={
                    dataOptions.deviceCategorySuggestion
                  }
                />
              </>
            ) : (
              <FilterLineLoader></FilterLineLoader>
            )
          ) : (
            <SimpleFilterList
              onChange={handleSimpleFilterList}
              data={dataOptions}
              searchPlaceholder={searchPlaceholder}
            />
          )}
        </Col>
      </Row>

      <div id="table-page-content">{children && children()}</div>
    </>
  );
};

export default ToolbarHeader;
