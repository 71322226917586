import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useOuterClick from "../useOutsideElement";
import GeneralFooterFilter from "../GeneralFooterFilter";
import { useSelector } from "react-redux";
import searchUIClient from "../../../apis/searchUIClient";
import { useOrg } from "../../../contexts/OrgContext";
import { Button, Container, Form } from "react-bootstrap";
import { AdvanceFilter, FilterBody, FilterHeaderStyle } from "../FilterStyle";
import CheckboxItem from "../CheckboxItem";
import SntCloseSmallIcon from "../../Icons/SntCloseSmallIcon";
import SntArrowDownIcon from "../../Icons/SntArrowDownIcon";
import usePositionFilter from "../usePositionFilter";
import SntSelect from "@wrappers/ReactSelect/SntSelect.js";
import { supportedOperatorConstant } from "@/components/SntTableViewCard/SntTableConfigurationConstant.js";

const MultiCheckboxFilter = ({
  descriptor = {
    key: "lastGeozone",
    label: "Geozone",
    description: "The last known geozone of the tracker",
    categoryId: "locationInfo",
    order: 10,
    filterType: "MULTI_SELECT_CHECKBOX",
    filterTypeSettings: {
      optionsURL: "/search/gui/filter/suggestion/lastGeozone",
      showOptionSearchFilter: true,
      showOptionHints: true,
      suggestedOptionsTitle: "Suggested geozones",
      supportedOperators: [],
    },
  },
  data = {
    filterKey: "geozoneTags",
    filterType: "MULTI_SELECT_CHECKBOX",
    categoryId: "geozoneInfo",
    searchType: "STATIC",
    filterValue: {
      selectedValues: [764, 770],
    },
  },
  onChange = (data) => {
    console.log("emit on onchange: ", data);
  },
  isShowExclude = true,
  disabled = false,
  deviceCategorySuggestion,
}) => {
  const language = useSelector((state) => state.language);
  const [description] = useState(descriptor.description || "");
  const [showOptionSearchFilter] = useState(
    (descriptor.filterTypeSettings &&
      descriptor.filterTypeSettings.showOptionSearchFilter) ||
      true
  );
  // belong to the button
  const [label, setLabel] = useState(
    descriptor.label || descriptor.description || ""
  );
  const [title, setTitle] = useState("");

  const [searchText, setSearchText] = useState("");
  const [isShow, setShow] = useState(false);

  // input data
  const [dataState, setDataState] = useState({
    selectedValues: [],
  });

  // original data get from server
  const [mapData, setMapData] = useState({});
  const [keysMap, setKeysMap] = useState({});
  const { orgId } = useOrg();
  // available selected items
  const [selectedList, setSelectedList] = useState([]);

  // available not seleted items
  const [unselectedList, setUnselectedList] = useState([]);

  // temp selected items
  const tempSelectedList = useRef([]);

  // use for lazy loading
  const categoryItemRef = useRef();
  const [availableUnselectedList, setAvailableUnselectedList] = useState([]);
  const [categoryIndex, setCategoryIndex] = useState(-1);
  const [delayLoading, setDelayLoading] = useState(null);
  const isScroll = useRef(true);
  const [isLoading, setIsLoading] = useState(true);

  let popupStyleRef = useRef({});
  const { popupRef, buttonRef, getPosition } = usePositionFilter();
  const [operatorOptions, setOperatorOptions] = useState([]);

  const updateTempSelectedList = (selectedList) => {
    selectedList.forEach((item, index) => {
      if (tempSelectedList.current.indexOf(Number.parseInt(item.key)) !== -1) {
        selectedList[index].checked = true;
      }
    });
  };

  const getUnselectedList = (data) => {
    // get from data
    if (data && data instanceof Object) {
      return Object.keys(mapData)
        .filter(
          (id) =>
            data.selectedValues.find((item) => item == mapData[id].key) ===
            undefined
        ) // use == to compare string and number. e.g 1=='1' => true
        .map((id) => {
          return {
            key: mapData[id].key,
            id: id,
            checked: false,
            label: mapData[id].label,
          };
        });
    }
    // get from original arr
    return Object.keys(mapData)
      .filter(
        (id) =>
          dataState.selectedValues.find((item) => item == mapData[id].key) ===
          undefined
      ) // use == to compare string and number e.g 1=='1' => true
      .map((id) => {
        return {
          key: mapData[id].key,
          id: id,
          checked: false,
          label: mapData[id].label,
        };
      });
  };

  const getSelectedList = (data) => {
    // get from data
    if (Object.keys(keysMap).length === 0) return [];

    if (data && data instanceof Object) {
      return data.selectedValues
        .filter((key) => keysMap[key] !== undefined)
        .map((key) => {
          return {
            key: key,
            id: keysMap[key],
            checked: true,
            label: mapData[keysMap[key]].label,
          };
        });
    }
    // get from original arr
    return dataState.selectedValues
      .filter((key) => keysMap[key] !== undefined)
      .map((key) => {
        return {
          key: key,
          id: keysMap[key],
          checked: true,
          label: mapData[keysMap[key]],
        };
      });
  };

  useEffect(() => {
    setCategoryIndex(0);
    setAvailableUnselectedList([...unselectedList.slice(0, 49)]);
    if (isScroll.current) {
      categoryItemRef.current.scrollTo(0, selectedList.length * 41);
    }
  }, [selectedList.length, unselectedList]);

  useEffect(() => {
    if (categoryIndex !== 0)
      setAvailableUnselectedList([
        ...availableUnselectedList,
        ...unselectedList.slice(
          categoryIndex * 50,
          categoryIndex * 50 + 50 - 1
        ),
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryIndex]);

  const onScrollDialog = () => {
    if (
      categoryItemRef.current.scrollHeight - categoryItemRef.current.scrollTop <
      400
    ) {
      clearTimeout(delayLoading);
      setDelayLoading(
        setTimeout(function () {
          setCategoryIndex((pre) => pre + 1);
        }, 100)
      );
    }
  };

  // on change search text
  useEffect(() => {
    isScroll.current = true;
    // there no data
    if (Object.keys(keysMap).length === 0 || Object.keys(mapData).length === 0)
      return;
    let _unselectedList = getUnselectedList();
    if (searchText.trim() !== "") {
      // filter by search text
      _unselectedList = _unselectedList.filter(
        (s) => s.label.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
      );
    }
    updateTempSelectedList(_unselectedList);
    setUnselectedList(_unselectedList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keysMap, mapData, searchText]);

  useEffect(() => {
    setLabel(descriptor.label || descriptor.description || "");

    // set for the button
    let { filterValue } = data;
    let _title = language.all_key,
      _dataState = {
        selectedValues: [],
      };

    if (filterValue) {
      _dataState = { selectedValues: [], ...filterValue };
      if (
        filterValue.selectedValues &&
        Object.keys(keysMap).length !== 0 &&
        Object.keys(mapData).length !== 0
      ) {
        if (filterValue.selectedValues.length > 2) {
          _title = filterValue.selectedValues.length;
        } else if (filterValue.selectedValues.length > 0) {
          _title = filterValue.selectedValues
            .map((s) => keysMap[s])
            .map((s) => (mapData[s] && mapData[s].label) || "")
            .filter((s) => s)
            .join(", ");
        }
      }

      if (
        filterValue.operator &&
        filterValue.operator !== supportedOperatorConstant.IN
      ) {
        _title = language["filter_operator_" + filterValue.operator + "_key"];
      }
    }
    setDataState(_dataState);
    setTitle(_title);

    // set for lists in the panel
    if (_dataState && _dataState.selectedValues) {
      let _unselectedList = getUnselectedList(_dataState);
      let _selectedList = getSelectedList(_dataState);

      setUnselectedList(_unselectedList);
      setSelectedList(_selectedList);
    }

    if (
      descriptor.filterTypeSettings &&
      descriptor.filterTypeSettings.supportedOperators &&
      descriptor.filterTypeSettings.supportedOperators.length > 1
    ) {
      let supportedOperators = descriptor.filterTypeSettings.supportedOperators;
      let supportedOperatorOptions = [];
      supportedOperators.forEach((operator) => {
        supportedOperatorOptions.push({
          value: operator,
          label: language["filter_operator_" + operator + "_key"],
        });
      });

      setOperatorOptions(supportedOperatorOptions);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data.filterValue, descriptor.filterTypeSettings, mapData, keysMap]);

  // load data from server
  useEffect(
    () => {
      if (descriptor.filterTypeSettings.optionsURL) {
        let param =
          descriptor.searchType === "DYNAMIC" ? "/" + descriptor.key : "";
        let orgParam = orgId && !disabled ? `?org=${orgId}` : "";

        let categorySuggestionParam = "";
        if (deviceCategorySuggestion && orgParam && orgParam !== "") {
          categorySuggestionParam = `&deviceCategorySuggestion=${deviceCategorySuggestion}`;
        } else if (deviceCategorySuggestion) {
          categorySuggestionParam = `?deviceCategorySuggestion=${deviceCategorySuggestion}`;
        }

        searchUIClient
          .getSuggestion({
            url:
              descriptor.filterTypeSettings.optionsURL +
              param +
              orgParam +
              categorySuggestionParam,
          })
          .then(({ data }) => {
            let _options = data.options,
              _mapData = {},
              _keysMap = {};

            for (var j = 0, _size = _options.length; j < _size; j++) {
              let data = _options[j],
                _key = data.key,
                _idx = "suggestion_" + j;

              _mapData[_idx] = data;
              _keysMap[_key] = _idx;
            }

            setMapData(_mapData);
            setKeysMap(_keysMap);
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [descriptor.filterTypeSettings.optionsURL, orgId]
  );

  const getData = useCallback(() => {
    let newData = JSON.parse(JSON.stringify(data));
    newData.filterValue = {
      selectedValues: Array.from(
        new Set([
          ...selectedList.filter((s) => s.checked).map((s) => s.key.toString()),
          ...unselectedList
            .filter((s) => s.checked)
            .map((s) => s.key.toString()),
          ...tempSelectedList.current.map((key) => key.toString()),
        ])
      ),
      operator: dataState.operator,
    };
    return newData;
  }, [data, dataState, selectedList, unselectedList]);

  const isChanged = useCallback(() => {
    let oldDataFilter = data.filterValue || {};
    let newData = getData();
    let newDataFilter = newData.filterValue || {};

    if (
      JSON.stringify([...(oldDataFilter.selectedValues || [])].sort()) ===
        JSON.stringify([...(newDataFilter.selectedValues || [])].sort()) &&
      oldDataFilter.operator === newDataFilter.operator
    )
      return false;
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dataState, selectedList, unselectedList]);

  const onAppliedFilter = () => {
    setShow(false);
    if (isChanged()) onChange && onChange(getData());
    tempSelectedList.current = [];
  };

  const onClearFilter = () => {
    setShow(false);
    onChange &&
      onChange({
        idx: descriptor._idx,
        data: null,
      });
    tempSelectedList.current = [];
  };

  const onClickButton = (e) => {
    if (isShow) {
      setShow(false);
      if (isChanged()) onChange && onChange(getData());
    } else {
      tempSelectedList.current = [];
      popupStyleRef.current = getPosition();
      setShow(true);
    }
  };

  const onFilterItems = (e) => {
    setSearchText(e.target.value);
  };

  // on click outside the panel
  let refWrapper = useOuterClick((e) => {
    if (isShow && e.target.id.indexOf("react-select") === -1) {
      setShow(false);
      if (isChanged()) onChange && onChange(getData());
    }
  });

  const availableOptionView = useMemo(() => {
    let { filterTypeSettings } = descriptor;
    if (filterTypeSettings) {
      return (
        <>
          {operatorOptions.length > 1 && (
            <Container className="pt-3">
              <SntSelect
                className="flex-grow-1"
                value={operatorOptions.filter(
                  (option) =>
                    option.value ===
                    (dataState.operator || operatorOptions[0].value)
                )}
                options={operatorOptions}
                onChange={(operatorOption) => {
                  setDataState({
                    ...dataState,
                    operator: operatorOption.value,
                  });
                }}
              />
            </Container>
          )}
        </>
      );
    }
    return null;
  }, [dataState, descriptor, operatorOptions]);

  const selectedOptionView = useMemo(() => {
    let _defaultClass = "label label-stop";

    let _selectedList = Array.from(selectedList);
    let selectedOptions = _selectedList.map(({ id, checked }, index) => {
      return (
        <CheckboxItem
          key={index}
          checked={checked}
          onChange={(value) => {
            _selectedList[index].checked = value;
            setSelectedList(_selectedList);
          }}
          optionHint={
            descriptor.filterTypeSettings.showOptionHints
              ? mapData[id].optionHint
              : ""
          }
          cssClass={mapData[id].cssClass || _defaultClass}
          label={mapData[id].label}
        />
      );
    });
    return <Container>{selectedOptions}</Container>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedList, descriptor]);

  const unselectedOptionView = useMemo(() => {
    let _defaultClass = "label label-stop";

    let _unselectedList = Array.from(availableUnselectedList);
    let selectedOptions = availableUnselectedList.map(
      ({ id, checked }, index) => {
        return (
          <CheckboxItem
            key={id}
            checked={checked}
            onChange={(value) => {
              _unselectedList[index].checked = value;
              isScroll.current = false;
              setAvailableUnselectedList(_unselectedList);
              if (value) {
                tempSelectedList.current = [
                  ...tempSelectedList.current,
                  mapData[id].key.toString(),
                ];
              } else {
                tempSelectedList.current.splice(
                  tempSelectedList.current.indexOf(mapData[id].key.toString()),
                  1
                );
                tempSelectedList.current = [...tempSelectedList.current];
              }
            }}
            cssClass={mapData[id].cssClass || _defaultClass}
            label={mapData[id].label}
            optionHint={
              descriptor.filterTypeSettings.showOptionHints
                ? mapData[id].optionHint
                : ""
            }
          />
        );
      }
    );
    return (
      <Container>
        {descriptor.filterTypeSettings.suggestedOptionsTitle && (
          <div className="fw-bold mt-3 mb-3">
            {descriptor.filterTypeSettings.suggestedOptionsTitle}
          </div>
        )}
        {selectedOptions}
      </Container>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableUnselectedList, descriptor]);

  return (
    <AdvanceFilter ref={refWrapper}>
      <Button
        ref={buttonRef}
        variant="sensolus-greylight"
        title={description}
        disabled={disabled || isLoading}
        onClick={onClickButton}
      >
        {(dataState.selectedValues.length > 0 || dataState.operator) && (
          <SntCloseSmallIcon
            className="me-1"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClearFilter();
            }}
          />
        )}
        <span>{label}</span>
        <FilterHeaderStyle>{title}</FilterHeaderStyle>
        <SntArrowDownIcon />
      </Button>
      <FilterBody
        ref={popupRef}
        style={{ display: isShow ? "block" : "none", ...popupStyleRef.current }}
      >
        {availableOptionView}
        <Container className="pt-3">
          {showOptionSearchFilter && (
            <div className="mb-3">
              <Form.Control
                name="search"
                type="text"
                value={searchText}
                placeholder={language.input_search_key}
                onChange={onFilterItems}
              />
            </div>
          )}
        </Container>
        <div
          ref={categoryItemRef}
          onScroll={onScrollDialog}
          id="scroll-category-item"
          style={{ overflowY: "auto", height: 300 + "px" }}
        >
          {![
            supportedOperatorConstant.NOT_NULL,
            supportedOperatorConstant.NULL,
          ].includes(dataState.operator) &&
            selectedList.length > 0 && (
              <>
                {selectedOptionView}
                <div className="mb-3 mt-3 border-top border-sensolus-grey"></div>
              </>
            )}

          {![
            supportedOperatorConstant.NOT_NULL,
            supportedOperatorConstant.NULL,
          ].includes(dataState.operator) && (
            <div style={{ position: "relative" }}>{unselectedOptionView}</div>
          )}
        </div>
        <GeneralFooterFilter
          onClearFilter={(e) => onClearFilter(e)}
          onAppliedFilter={(e) => onAppliedFilter(e)}
          isShowExclude={false}
        />
      </FilterBody>
    </AdvanceFilter>
  );
};

export default MultiCheckboxFilter;
