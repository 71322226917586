import { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import SntCheckBox from "../SntCheckBox/SntCheckBox";
import styled from "styled-components";

const FooterStyle = styled.div`
  & > * {
    margin: calc(0.5rem * 0.5);
  }
`;

const GeneralFooterFilter = ({
  onClearFilter,
  onAppliedFilter,
  isNotFilter = false,
  onCheckNotFilter,
  disableCheckBox = true,
  isShowExclude = true,
}) => {
  const language = useSelector((state) => state.language);

  useEffect(() => {
    if (disableCheckBox) {
      onCheckNotFilter && onCheckNotFilter(false);
    }
    // eslint-disable-next-line
  }, [disableCheckBox]);
  return (
    <>
      <div className="mb-3 border-top border-sensolus-grey"></div>
      <Container>
        <div className="mb-3 justify-content-between d-flex">
          <div className="align-self-center">
            {isShowExclude && (
              <>
                <SntCheckBox
                  checked={disableCheckBox ? false : isNotFilter}
                  onChange={onCheckNotFilter}
                  disabled={disableCheckBox}
                  label={
                    <label className="fw-bold">{language.exclude_key}</label>
                  }
                />
              </>
            )}
          </div>
          <FooterStyle className="align-self-center">
            <Button variant="outline-sensolus" onClick={onClearFilter}>
              {language.clear_key}
            </Button>
            <Button variant="sensolus" onClick={onAppliedFilter}>
              {language.apply_key}
            </Button>
          </FooterStyle>
        </div>
      </Container>
    </>
  );
};

export default GeneralFooterFilter;
