import L from "leaflet";
import areaClient from "@apis/areaClient";

L.GeozoneLayer = L.FeatureGroup.extend({
  options: {},
  bounds: L.latLngBounds(),
  updateData: function (orgIds, options) {
    var self = this;
    var polygons = [];
    areaClient.getAreaByOrgs(orgIds).then(
      ({ data }) => {
        self.clearLayers();
        const aggregatedTooltip = L.tooltip({
          permanent: false,
          direction: "center",
          offset: [0, -4], // Adjust offset if needed
        });

        let geozones = data.sort((a, b) => (a.surface > b.surface ? -1 : 1));
        for (var i = 0; i < geozones.length; i++) {
          var obj = geozones[i];

          if (options && +options.ignoreGeozoneId === obj.id) {
            continue;
          }

          var geozone = null;
          if (obj.circleGeozone) {
            geozone = L.circle(
              [obj.circleGeozone.point.x, obj.circleGeozone.point.y],
              {
                pane: "shadowPane",
                color: obj.borderColor,
                weight: 1,
                fillColor: obj.contentColor,
                radius: obj.circleGeozone.radius,
                _type: "CIRCLE",
                name: obj.name,
              }
            );
            // .bindTooltip(obj.name, { sticky: true, offset: [0, -4] });
            self.bounds.extend([
              obj.circleGeozone.point.x,
              obj.circleGeozone.point.y,
            ]);
          } else {
            var coordinates = obj.coordinates || [],
              path = [];
            for (var j = 0, len = coordinates.length; j < len; j++) {
              path.push([coordinates[j].x, coordinates[j].y]);
            }
            geozone = L.polygon(path, {
              pane: "shadowPane",
              weight: 1,
              fillColor: obj.contentColor,
              color: obj.borderColor,
              name: obj.name,
            });
            // .bindTooltip(obj.name, { sticky: true, offset: [0, -4] });
            self.bounds.extend(path);
          }

          geozone.on("mousemove", (event) => {
            // const currentPolygon = event.target;
            const latlng = event.latlng;
            const overlappingPolygons = self.findPolygonsContainPoint(
              latlng,
              polygons
            );
            const titles = overlappingPolygons.map(
              (polygon) => polygon.options.name
            );
            self.updateAggregatedTooltip(titles, latlng, aggregatedTooltip);
            self.addLayer(aggregatedTooltip);
          });

          // Add mouseout event listener to hide all tooltips
          geozone.on("mouseout", () => {
            self.removeLayer(aggregatedTooltip);
          });

          polygons.push(geozone);
          self.addLayer(geozone);
        }
      },
      (err) => {
        // setLoading(false);
      }
    );
  },
  pointInsidePolygon: function (point, polygon) {
    let inside = false;
    const x = point.lat;
    const y = point.lng;

    if (polygon instanceof L.Circle) {
      return polygon.getBounds().contains(point); // For circles, use bounds.contains
    }

    for (var ii = 0; ii < polygon.getLatLngs().length; ii++) {
      var polyPoints = polygon.getLatLngs()[ii];
      for (
        var i = 0, j = polyPoints.length - 1;
        i < polyPoints.length;
        j = i++
      ) {
        var xi = polyPoints[i].lat,
          yi = polyPoints[i].lng;
        var xj = polyPoints[j].lat,
          yj = polyPoints[j].lng;

        var intersect =
          yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
      }
    }

    return inside;
  },
  updateAggregatedTooltip: function (titles, latlng, aggregatedTooltip) {
    const tooltipContent = titles.join("<br>"); // Join titles with line breaks
    aggregatedTooltip.setContent(tooltipContent);
    aggregatedTooltip.setLatLng(latlng);
  },
  findPolygonsContainPoint: function (latlng, polygons) {
    const overlappingPolygons = [];

    polygons.forEach((polygon) => {
      if (this.pointInsidePolygon(latlng, polygon)) {
        overlappingPolygons.push(polygon);
      }
    });

    return overlappingPolygons;
  },
  getLayer: function () {
    return this;
  },
  getBounds: function () {
    return this.bounds;
  },
});

L.geozoneLayer = function (opts) {
  return new L.GeozoneLayer(opts);
};
export const GeozoneLayer = L.geozoneLayer;
