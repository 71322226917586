import { useEffect, useRef } from "react";
// 3rd parties
import L from "leaflet";

// owner
import CommonMapReact from "@containers/CommonMapReact/CommonMapReact";
import MarkerIcon from "@images/mapbox/images/marker-icon.png";
import TowerIcon from "@images/map/Cell_tower.svg";
import WifiIcon from "@images/map/Wi-Fi.svg";

import SntInfoPanel from "@/components/SntInfo/SntInfoPanel";

function MapUpdateWifiCellularMismatch({ item, onClick }) {
  const map = useRef();
  const marker = useRef();
  const wifiMarker = useRef();
  const towerMarker = useRef();

  useEffect(() => {
    let bounds = L.latLngBounds();
    if (marker.current) {
      map.current.removeLayer(marker.current);
    }
    if (wifiMarker.current) {
      map.current.removeLayer(wifiMarker.current);
    }
    if (towerMarker.current) {
      map.current.removeLayer(towerMarker.current);
    }

    if (item.wifiLat && item.wifiLng) {
      wifiMarker.current = new L.marker([item.wifiLat, item.wifiLng], {
        icon: L.icon({ iconUrl: WifiIcon, iconAnchor: [13, 41] }),
      }).addTo(map.current);
      bounds.extend(wifiMarker.current.getLatLng());
    }

    if (item.cellLat && item.cellLng) {
      towerMarker.current = new L.marker([item.cellLat, item.cellLng], {
        icon: L.icon({ iconUrl: TowerIcon, iconAnchor: [13, 41] }),
      }).addTo(map.current);
      bounds.extend(towerMarker.current.getLatLng());
    }
    
    bounds.isValid() &&
      map.current.fitBounds(bounds, { animate: true, maxZoom: 12 });

    map.current.on("click", function (event) {
    if (marker.current) {
      map.current.removeLayer(marker.current);
    }

    marker.current = new L.marker([event.latlng.lat, event.latlng.lng], {
      icon: L.icon({ iconUrl: MarkerIcon, iconAnchor: [13, 41] }),
      draggable: "true",
    });

    marker.current
      .bindTooltip("(" + event.latlng.lat + ", " + event.latlng.lng + ")")
      .addTo(map.current);

    marker.current.on("dragend", function (event) {
      marker.current.bindTooltip(
        "(" + event.target._latlng.lat + ", " + event.target._latlng.lng + ")"
      );
      onClick &&
        onClick({
          latitude: event.target._latlng.lat,
          longitude: event.target._latlng.lng,
        });
    });

    onClick &&
      onClick({
        latitude: event.latlng.lat,
        longitude: event.latlng.lng,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <>
      <SntInfoPanel>
        {"Click to map to choose new location for cellular tower"}
      </SntInfoPanel>
      <CommonMapReact
        mapRef={map}
        height="600px"
        options={{
          zoomPosition: "topright",
          hideCurrentLocation: true,
          hideFullScreen: true,
          showGeozoneLayer: true,
          showNetworkLayer: true,
          searchBox: true,
        }}
      />
    </>
  );
}

export default MapUpdateWifiCellularMismatch;
