import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import wifiCellularMismatchClient from "@/apis/wifiCellularMismatchClient";
import useDeleteDialog from "@/cleanup/widgets/dialog/useDeleteDialog";
import SntDeleteIcon from "@/components/Icons/SntDeleteIcon";
import SntDateView from "@/components/ReactBootstrap/SntDateView";
import SntText from "@/components/ReactBootstrap/SntText";
import SntPencilIcon from "../../components/Icons/SntPencilIcon";
import SntActionButton from "../../components/ReactBootstrap/SntActionButton";
import SntAdvancedSearchTableViewCard from "../../components/SntTableViewCard/SntAdvancedSearchTableViewCard";
import AdminLayout from "../../containers/AdminLayout/AdminLayout";
import ActionToolEnum from "../../containers/AdminLayout/Toolbar/ActionToolEnum";
import ActionToolType from "../../containers/AdminLayout/Toolbar/ActionToolType";
import UpdateMismatchPopup from "./UpdateMismatchPopup";
import { DefaultTag } from "@/components/ReactBootstrap/TagMore";
import SntLink from "@/components/ReactBootstrap/SntLink";
import convert from "convert-units";
import IcomoonIcon from "@/components/Icons/IcomoonIcon";

const PAGE_NAME = "WIFI_CELLULAR_MISMATCH";
const DEFAULT_OPTION = {
  pageName: PAGE_NAME,
  queryObjectType: "WIFI_CELLULAR_MISMATCH",
  filtersVisibleAtInit: ["deviceSerial", "cellId", "createdAt"],
  optionsSettings: {},
};

const LocationMismatch = ({ reload }) => {
  //redux state
  const language = useSelector((state) => state.language);
  const table = useRef();
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const loginInfo = useSelector((state) => state.user);
  let { unitDistance, permissionsMap } = loginInfo;

  const filterList = [
    {
      id: ActionToolEnum.save_filter,
      title: language.save_filter,
      type: ActionToolType.filter,
    },
    {
      id: ActionToolEnum.load_filter,
      title: language.load_filter,
      type: ActionToolType.filter,
    },
  ];

  const allColumns = useMemo(
    () => [
      {
        key: "cellId",
        title: language.cell_id,
        Cell: ({ cell, value }) => {
          return <SntText value={value}></SntText>;
        },
      },
      {
        key: "deviceSerial",
        title: language.serial_key,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          return value ? (
            <SntLink to={`/tracked_assets_update?type=view&serial=${value}`}>
              {value}
            </SntLink>
          ) : (
            ""
          );
        },
      },
      {
        key: "wifiProvider",
        title: language.provider_key,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "macs",
        title: language.wifi_mac_addresses_key,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          return (
            value?.map((m) => (
              <DefaultTag key={m} tagName={m} className="me-1 mb-1" />
            )) || ""
          );
        },
      },
      {
        key: "wifiLocation",
        title: language.journey_wifi_location_key,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          var full = cell.row.original;
          return full.wifiLat && full.wifiLng ? (
            <>
              <div className="d-flex">
                latitude: {full.wifiLat} &nbsp;
                <a
                  target=" "
                  href={
                    "http://maps.google.com/maps?q=loc:" +
                    full.wifiLat +
                    "," +
                    full.wifiLng
                  }
                >
                  <IcomoonIcon
                    icon="map2"
                    size="12"
                    color="#6EDC85"
                  ></IcomoonIcon>
                </a>
              </div>
              <div>longitude: {full.wifiLng}</div>
            </>
          ) : (
            ""
          );
        },
      },
      {
        key: "cellularInfo",
        title: language.cellular_tower_info,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          var full = cell.row.original;
          var info =
            "mcc: " +
            (full.mcc || language.unknown_key) +
            ", mnc: " +
            (full.net || language.unknown_key) +
            ", cell: " +
            (full.cellId || language.unknown_key);
          return <div>{info}</div>;
        },
      },
      {
        key: "cellularLocation",
        title: language.cellular_tower_location,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          var full = cell.row.original;
          return full.cellLat && full.cellLng ? (
            <>
              <div className="d-flex">
                latitude: {full.cellLat} &nbsp;
                <a
                  target=" "
                  href={
                    "http://maps.google.com/maps?q=loc:" +
                    full.cellLat +
                    "," +
                    full.cellLng
                  }
                >
                  <IcomoonIcon
                    icon="map2"
                    size="12"
                    color="#6EDC85"
                  ></IcomoonIcon>
                </a>
              </div>
              <div>longitude: {full.cellLng}</div>
            </>
          ) : (
            ""
          );
        },
      },
      {
        key: "distance",
        title: language.distance_key,
        Cell: ({ cell, value }) => {
          if (value) {
            if (unitDistance === "KILOMETER") {
              return (
                Math.round(convert(value).from("m").to("km")) +
                " " +
                language.maintenance_unit_KM_key
              );
            } else {
              return (
                Math.round(convert(value).from("m").to("mi")) +
                " " +
                language.maintenance_unit_MI_key
              );
            }
          } else {
            return "";
          }
        },
      },
      {
        key: "createdAt",
        title: language.created_date_key,
        Cell: ({ value }) => {
          return (
            <SntDateView
              value={value}
              other={language.na_key}
              format="lll"
            ></SntDateView>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const defaultColumnInit = useMemo(
    () => [
      "deviceSerial",
      "macs",
      "wifiLocation",
      "cellularLocation",
      "distance",
      "createdAt",
    ],
    []
  );

  const extraCols = useMemo(
    () => [
      {
        key: "actions",
        title: language.actions_key,
        immovable: true,
        disableSortBy: true,
        canResize: false,
        Cell: ({ cell }) => {
          var full = cell.row.original;
          return (
            <div>
              {permissionsMap["CELL_TOWER_TROUBLESHOOTING_MANAGE"] && (
                <>
                  <SntActionButton
                    title={language.edit_key}
                    onClick={(e) => {
                      setShowUpdatePopup(true);
                      setSelectedItem(full);
                    }}
                  >
                    <SntPencilIcon />
                  </SntActionButton>

                  <SntActionButton
                    title={language.delete_key}
                    onClick={(e) => onDeleteClicked(e, full)}
                  >
                    <SntDeleteIcon />
                  </SntActionButton>
                </>
              )}
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onDataRequestChange = (data, callback) => {
    const { params, dataOptions } = data;
    if (table.current) {
      table.current.setLoading(true);
      wifiCellularMismatchClient.search(params).then(
        ({ data }) => {
          if (table.current) {
            table.current.updateDataTable({
              data: data.data,
              pageCount: data.countByFilter,
              totalItem: {
                countAll: data.countAll,
                countByFilter: data.countByFilter,
              },
            });
            table.current.setLoading(false);

            const dataCallback = table.current.getDataCallBack(
              params,
              dataOptions,
              data
            );
            callback(dataCallback[0], dataCallback[1]);
          }
        },
        (err) => {
          table.current.setLoading(false);
        }
      );
    }
  };

  const {
    handleOpen,
    handleClose,
    handleOpenError,
    DeleteDialog,
  } = useDeleteDialog({
    onDelete: (itemSelected) => {
      wifiCellularMismatchClient
        .delete(itemSelected.id)
        .then(() => {
          handleClose();
          table.current.resetDefault();
        })
        .catch((error) => {
          handleClose();
          let message = "";
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message = error.response.data.message;
          }
          handleOpenError(message);
        });
    },
    language,
  });

  const onDeleteClicked = (el, item) => {
    el.preventDefault();
    handleOpen(
      item,
      item.name &&
        language.delete_message_with_name_key.replace("{0}", item.name)
    );
  };

  return (
    <div>
      <DeleteDialog />

      <UpdateMismatchPopup
        selectedItem={selectedItem}
        onClose={() => setShowUpdatePopup(false)}
        isShow={showUpdatePopup}
        setIsShow={setShowUpdatePopup}
      />

      <AdminLayout
        isShowHeader={false}
        browserTabTitle={language.location_mismatches}
        reload={reload}
        pageName={PAGE_NAME}
        sharedKey={PAGE_NAME}
        defaultOption={DEFAULT_OPTION}
        isAdvanceFilter={true}
        filterList={filterList}
        totalLabel={language.items_key}
        body={({ isDisabled, dataOptions, onChangeChildren }) =>
          dataOptions && (
            <SntAdvancedSearchTableViewCard
              shared={(child) => (table.current = child)}
              allColumns={allColumns}
              extraCols={extraCols}
              visibleCols={defaultColumnInit}
              disabled={isDisabled}
              onChange={(data) => {
                onDataRequestChange(data, onChangeChildren);
              }}
              dataOptions={dataOptions}
            />
          )
        }
      ></AdminLayout>
    </div>
  );
};

export default LocationMismatch;
