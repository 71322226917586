import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import {
  bucketTypeConstant,
  supportedGroupTypeConstant,
} from "@/components/SntTableViewCard/SntTableConfigurationConstant.js";
import SntLink from "@/components/ReactBootstrap/SntLink";

/**
 * Custom hook for managing data filtering, pagination, and sorting.
 *
 * @param {Array} allColumnDefs - The definition of all available columns.
 * @param {Array} defaultVisibleColumnKeys - The keys of the columns to be shown and ordered.
 * @param {Object} initialState - Optional initial state for the filter.
 * @returns {Object} - An object containing filter state and functions to interact with it.
 */
export const useColumnFilter = (allColumnDefs, initialState, setting) => {
  const [filter, setFilter] = useState(null);

  const [initState, setInitState] = useState(null);
  const [allColumns, setAllColumns] = useState(null);

  const [pageCount, setPageCount] = useState(1);
  const [totalItems, setTotalItems] = useState({
    countAll: 0,
    countByFilter: 0,
  });

  let allColumnsByKeyRef = useRef();
  let immovableColumnsRef = useRef();
  let defaultColumnsRef = useRef();

  let allColumnsByKey = allColumnsByKeyRef.current;
  let immovableColumns = immovableColumnsRef.current;
  let defaultColumns = defaultColumnsRef.current;
  const {
    defaultVisibleColumnKeys,
    defaultPageSize = 0,
    onCountClicked,
  } = initialState;

  useEffect(() => {
    if (!allColumnDefs) return;
    const allColumnTransform = _transformColumns(allColumnDefs);
    allColumnsByKeyRef.current = _.keyBy(
      allColumnTransform,
      (o) => o.columnKey
    );
    immovableColumnsRef.current = allColumnTransform
      .filter((c) => (c.immovable ? c : null))
      .filter((c) => c);
    setAllColumns(allColumnTransform);
  }, [allColumnDefs]);

  useEffect(() => {
    if (!allColumns) return;
    defaultColumnsRef.current = getOrderedColumns(defaultVisibleColumnKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allColumns]);

  useEffect(() => {
    if (initState) return;

    if (_.isNil(setting)) {
      setInitState({
        columnVisibleInOrder: defaultVisibleColumnKeys,
        pageSize: defaultPageSize,
      });
    } else if (!_.isEmpty(setting)) {
      setInitState({
        columnVisibleInOrder:
          setting.columnVisibleInOrder &&
          setting.columnVisibleInOrder.length !== 0
            ? setting.columnVisibleInOrder
            : defaultVisibleColumnKeys,
        pageSize: setting.pageSize || defaultPageSize,
        pageIndex: setting.pageIndex,
        orderData: setting.orderData,
        groups: setting.groups,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setting, initState]);

  useEffect(() => {
    if (!allColumns || !initState || filter) return;

    let sortBy = {
      column: defaultColumnsRef.current[0],
      desc: defaultColumnsRef.current[0].desc,
      visible: true,
    };

    const columns = getOrderedColumns(initState.columnVisibleInOrder);

    groupColumns.current = [];
    if (initState.groups && initState.groups.length) {
      const columnKey = initState.groups[0].aggregateColumn;
      const bucketType = initState.groups[0].bucketType;
      const bucketConfig = initState.groups[0].bucketConfig;
      const firstCol = columns.find((column) => column.key === columnKey);
      if (firstCol) {
        firstCol.isGrouped = true;
        firstCol.bucketType = bucketType;
        firstCol.bucketConfig = bucketConfig;
        groupColumns.current.push({
          ...firstCol,
          bucketType: bucketType,
          bucketConfig: bucketConfig,
          aggregationType: "COUNT",
          groupKey: columnKey,
          columnKey: columnKey + ".count",
          sortKey: columnKey + ".count",
          disabledGroupColumn: true,
          isGrouped: false,
          id: columnKey + ".count",
          Header: "Count",
          Cell: ({ cell, value }) => {
            return countColumnRendering(
              cell.row.original,
              firstCol,
              bucketType,
              bucketConfig
            );
          },
        });
        columns.push(groupColumns.current[0]);
      }
    }

    if (initState.orderData) {
      const orderColumn = columns.find(
        (column) => column.key === initState.orderData[0]
      );
      if (orderColumn) {
        sortBy = {
          column: allColumnsByKey[initState.orderData[0]],
          desc: initState.orderData[1] === "desc" ? true : false,
          visible: true,
        };
      } else if (
        groupColumns.current[0] &&
        groupColumns.current[0].sortKey === initState.orderData[0]
      ) {
        sortBy = {
          column: groupColumns.current[0],
          desc: initState.orderData[1] === "desc" ? true : false,
          visible: true,
        };
      }
    }

    setFilter({
      pageSize: initState.pageSize || 25,
      pageIndex: initState.pageIndex || 0,
      sortBy: sortBy,
      columns: columns,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allColumns, initState, filter]);

  const getOrderedColumns = (columnKeys, isOrdered = false) => {
    // sort by columnKeys and add immovables at the back !
    let keys = Array.from(new Set(columnKeys));
    let columns = keys
      .map((key) =>
        allColumnsByKey[key] && !allColumnsByKey[key].immovable
          ? allColumnsByKey[key]
          : null
      )
      .filter((n) => n);

    if (isOrdered) {
      columns.sort((col1, col2) => {
        return col1.sortedIndex - col2.sortedIndex;
      });
    }

    return columns.concat(immovableColumns);
  };

  const countColumnRendering = (full, column, bucketType, bucketConfig) => {
    if (
      onCountClicked &&
      column.redirectFilter &&
      column.redirectColumn &&
      full[column.redirectColumn.alias] &&
      full[column.redirectColumn.alias] !== '{"id": null, "name": null}'
    ) {
      return (
        <SntLink
          onClick={() => {
            onCountClicked(
              column.redirectFilter,
              full[column.redirectColumn.alias],
              bucketType,
              bucketConfig
            );
          }}
        >
          {full["count"] || 0}
        </SntLink>
      );
    }
    return full["count"] || 0;
  };

  const loadData = () => {
    setPaging(filter.pageIndex, filter.pageSize);
    setPageCount(1);
    _updateFilter({});
  };

  const resetDefault = () => {
    setPaging(0, filter.pageSize);
    setSortByKey(defaultColumns[0], false);
  };

  const refresh = () => {
    _updateFilter({});
  };

  const setVisibleColumnKeys = (visibleColumns, isOrdered = false) => {
    let newColumns = [];
    if (visibleColumns && visibleColumns.length > 0) {
      newColumns = getOrderedColumns(visibleColumns, isOrdered);
    }
    if (!newColumns.length) {
      newColumns = defaultColumns;
    }

    let columns = filter.columns.filter((col) => !col.aggregationType);
    newColumns = newColumns.map((item) => {
      const rs = { ...item };
      delete rs.isGrouped;
      return rs;
    });
    if (!_.isEqual(columns, newColumns)) {
      _updateFilter({ columns: newColumns });
    }
  };

  const setPaging = (pageIndex, pageSize) => {
    if (pageIndex !== filter.pageIndex || pageSize !== filter.pageSize) {
      _updateFilter({ pageSize: pageSize, pageIndex: pageIndex });
    }
  };

  const setSortByKey = (key, desc) => {
    let sortColumn = allColumnsByKey[key];
    if (!sortColumn) {
      sortColumn = groupColumns.current[0];
    }
    if (sortColumn && _sortChanged(sortColumn, desc)) {
      _updateFilter({
        sortBy: {
          column: sortColumn,
          desc: desc,
          visible: _isVisible(sortColumn),
        },
      });
    }
  };

  // const setFilterField = (name, value) => {
  //   if (!_.isEqual(value, filter[name])) {
  //     _updateFilter({ [name]: value });
  //   }
  // };

  const _sortChanged = (column, desc) => {
    return (
      !_.isEqual(column, filter.sortBy.column) || desc !== filter.sortBy.desc
    );
  };

  const _isVisible = (column) => {
    return (
      filter.columns &&
      filter.columns.filter((c) => c.columnKey === column.columnKey).length > 0
    );
  };

  const _updateFilter = (props) => {
    setFilter((prevFilter) => {
      let value = {
        ...prevFilter,
        ...props,
      };
      return value;
    });
  };

  const _transformColumns = (columns) => {
    return columns.map((column) => {
      return {
        ...column,
        sortedIndex: column.sortedIndex,
        categoryId: column.categoryId || "",
        category: column.category || "",
        columnKey: column.key,
        supportKibanaKey: column.supportKibanaKey,
        supportedGroupType: column.supportedGroupType,
        supportedGroupUnits: column.supportedGroupUnits,
        aggregateColumn: column.aggregateColumn,
        redirectFilter: column.redirectFilter,
        redirectColumn: column.redirectColumn,
        disabledGroupColumn: column.disabledGroupColumn,
        sortKey: column.sortKey || column.key,
        description: column.description ? column.description : "",
        title: column.title,
        accessor: column.key,
        Header: column.title || "",
        width: column.style && column.style.width ? column.style.width : null,
        minWidth:
          column.style && column.style.minWidth ? column.style.minWidth : 100,
        maxWidth:
          column.style && column.style.maxWidth ? column.style.maxWidth : 600,
      };
    });
  };

  function getPageData() {
    let sort = null;
    if (filter.sortBy) {
      sort = `[{"property": "${filter.sortBy.column.sortKey}", "direction": "${
        filter.sortBy.desc ? "DESC" : "ASC"
      }"}]`;
    }

    let columns = filter.columns.filter((c) => c.key !== "actions");
    let findGroup = filter.columns.find((col) => col.aggregationType);
    if (findGroup) {
      columns = columns.filter(
        (item) => findGroup.aggregateColumn === item.aggregateColumn
      );

      let groups = [
        {
          aggregationType: findGroup.aggregationType,
          aggregateColumn: findGroup.aggregateColumn,
          bucketType: findGroup.bucketType,
          bucketConfig: findGroup.bucketConfig,
        },
      ];

      return {
        start: filter.pageSize * filter.pageIndex,
        limit: filter.pageSize,
        sort: sort,
        isGrouped: true,
        groups: groups,
        requestedTableColumns: columns.map((column) => {
          return {
            bucketType: column.aggregationType ? null : column.bucketType,
            bucketConfig: column.aggregationType ? null : column.bucketConfig,
            aggregationType: column.aggregationType,
            order:
              filter.sortBy.column.columnKey === column.columnKey
                ? filter.sortBy.desc
                  ? "DESC"
                  : "ASC"
                : null,
            columnKey: column.groupKey || column.columnKey, // make sure count column and group column using same columnKey
          };
        }),
      };
    }

    return {
      start: filter.pageSize * filter.pageIndex,
      limit: filter.pageSize,
      sort: sort,
      requestedTableColumns: columns.map((column) => column.key),
    };
  }
  const groupColumns = useRef([]);

  const toggleGroupColumnKey = (columnKey, isForceGroup) => {
    // remove grouped
    let columns = filter.columns
      .filter((column) => !column.aggregationType)
      .map((column) => {
        if (column.key !== columnKey) delete column.isGrouped;
        if (isForceGroup) delete column.isGrouped;
        return column;
      });

    let firstCol = columns.find((column) => column.key === columnKey);
    // The following grouping logic relies on the user clicking a column header to initiate the grouping.
    // The grouped column may become undefined if this grouping action is used at another place
    if (!firstCol) {
      firstCol = allColumnsByKey[columnKey];
      columns.push(firstCol);
    }
    firstCol.isGrouped = !firstCol.isGrouped;

    // needed for mobile card checking false/true/null
    if (!firstCol.isGrouped) {
      delete firstCol.isGrouped;
      delete firstCol.bucketType;
      delete firstCol.bucketConfig;
    }

    let sortBy = filter.sortBy;
    sortBy.column = firstCol;

    groupColumns.current = [];
    if (firstCol.isGrouped) {
      sortBy = { ...sortBy };
      sortBy.desc = false;

      let bucketType =
          firstCol.supportedGroupType === supportedGroupTypeConstant.DATE_BUCKET
            ? bucketTypeConstant.DAY
            : firstCol.supportedGroupType ===
              supportedGroupTypeConstant.NUMERIC_BUCKET
            ? bucketTypeConstant.AUTO_WIDTH
            : firstCol.supportedGroupType ===
              supportedGroupTypeConstant.TAG_BASED
            ? bucketTypeConstant.TAGS_FULL
            : null,
        bucketConfig = null;

      groupColumns.current.push({
        ...firstCol,
        bucketType: bucketType,
        bucketConfig: bucketConfig,
        aggregationType: "COUNT",
        groupKey: columnKey,
        key: columnKey + ".count",
        columnKey: columnKey + ".count",
        sortKey: columnKey + ".count",
        disabledGroupColumn: true,
        isGrouped: false,
        id: columnKey + ".count",
        Header: "Count",
        Cell: ({ cell, value }) => {
          return countColumnRendering(
            cell.row.original,
            firstCol,
            bucketType,
            bucketConfig
          );
        },
      });
    }

    _updateFilter({
      columns: [...columns, ...groupColumns.current],
      sortBy: sortBy,
      pageIndex: 0,
    });
  };

  const setColumnsSetting = (
    columnKeys,
    sortBy = { key: "", desc: false },
    groupKey,
    bucketType,
    bucketConfig
  ) => {
    let columns = [];
    if (columnKeys.length > 0) {
      columns = getOrderedColumns(columnKeys, false);
    }
    if (!columns.length) {
      columns = defaultColumns;
    }

    columns.map((column) => {
      delete column.isGrouped;
      delete column.bucketType;
      delete column.bucketConfig;
      return column;
    });

    let sort = filter.sortBy;

    groupColumns.current = [];
    if (groupKey) {
      let firstCol = columns.find((column) => column.key === groupKey);
      firstCol.isGrouped = true;
      firstCol.bucketType =
        firstCol.supportedGroupType === supportedGroupTypeConstant.DATE_BUCKET
          ? bucketType || bucketTypeConstant.DAY
          : firstCol.supportedGroupType ===
            supportedGroupTypeConstant.NUMERIC_BUCKET
          ? bucketType || bucketTypeConstant.AUTO_WIDTH
          : firstCol.supportedGroupType === supportedGroupTypeConstant.TAG_BASED
          ? bucketType || bucketTypeConstant.TAGS_FULL
          : null;
      firstCol.bucketConfig =
        firstCol.supportedGroupType ===
          supportedGroupTypeConstant.NUMERIC_BUCKET &&
        bucketType === bucketTypeConstant.WIDTH
          ? bucketConfig
          : null;

      groupColumns.current.push({
        ...firstCol,
        aggregationType: "COUNT",
        groupKey: groupKey,
        key: groupKey + ".count",
        columnKey: groupKey + ".count",
        sortKey: groupKey + ".count",
        disabledGroupColumn: true,
        isGrouped: false,
        id: groupKey + ".count",
        Header: "Count",
        Cell: ({ cell, value }) => {
          return countColumnRendering(
            cell.row.original,
            firstCol,
            firstCol.bucketType,
            firstCol.bucketConfig
          );
        },
      });

      if (firstCol.key === sortBy.key) {
        sort.column = firstCol;
      } else {
        sort.column = groupColumns.current[0];
      }
      sort.desc = sortBy.desc || false;
    } else {
      let sortColumn = columns.find((column) => column.key === sortBy.key);
      sort.column = sortColumn || columns[0];
      sort.desc = sortBy.desc || false; // sort.desc does not accept undefined or null value
    }

    _updateFilter({
      columns: [...columns, ...groupColumns.current],
      sortBy: { ...sort },
      pageIndex: 0,
    });
  };

  const removeColumnKey = (columnKey) => {
    let columns = filter.columns.filter((column) => column.key !== columnKey);
    _updateFilter({ columns: columns });
  };

  if (!filter) return {};
  return {
    filter,
    allColumns,
    getPageData,
    setPaging,
    pageCount,
    setPageCount,
    setSortByKey,
    // setFilterField,
    setVisibleColumnKeys,
    refresh,
    loadData,
    resetDefault,
    totalItems,
    setTotalItems,
    removeColumnKey,
    toggleGroupColumnKey,
    defaultVisibleColumnKeys,
    setColumnsSetting,
  };
};
