import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useWindowSize } from "react-use";

const Toolbar = ({
  handleClick = () => {},
  selectedId,
  sections = [
    [
      {
        id: "LIST",
        title: "list",
        icon: "",
        link: "",
      },
    ],
  ],
}) => {
  const history = useHistory();

  const delay = useRef(null);
  const tabWidget = useRef(null);
  const container = useRef(null);
  const [hiddenItems, setHiddenItems] = useState([]);
  const [visibleItems, setVisibleItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(selectedId);
  useEffect(() => {
    selectedId && setSelectedItem(selectedId);
  }, [selectedId]);

  const hiddenItemRender = useMemo(() => {
    return hiddenItems.map((section, key) => {
      return section.map((item, itemIdx) => (
        <Dropdown.Item
          key={itemIdx}
          className={`${
            item.id === selectedItem ? item.customActiveClass || "active" : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (item.fullLinkInNewTab) {
              window.open(item.fullLinkInNewTab);
            } else if (item.link) {
              history.push(item.link);
            } else {
              handleClick(item.id);
            }
            setSelectedItem(item.id);
          }}
        >
          {item.icon && <span className="me-2">{item.icon}</span>}
          {item.title}
        </Dropdown.Item>
      ));
    });
  }, [hiddenItems, handleClick, history, selectedItem]);

  const visibleItemRender = useMemo(() => {
    return visibleItems.map((section, key) => {
      return (
        <div key={key} className="d-inline-block ms-2">
          <ButtonGroup>
            {section.map((item, itemIdx) => {
              return (
                <Button
                  key={itemIdx}
                  variant="sensolus-greylight"
                  className={`${
                    item.id === selectedItem
                      ? item.customActiveClass || "active"
                      : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (item.fullLinkInNewTab) {
                      window.open(item.fullLinkInNewTab);
                    } else if (item.link) {
                      history.push(item.link);
                    } else {
                      handleClick(item.id);
                    }
                    setSelectedItem(item.id);
                  }}
                >
                  {item.icon ? item.icon : ""}
                  {item.title && (
                    <span className="ms-1 d-none d-lg-inline-block">
                      {item.title}
                    </span>
                  )}
                </Button>
              );
            })}
          </ButtonGroup>
        </div>
      );
    });
  }, [visibleItems, handleClick, history, selectedItem]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounce = useCallback(
    _.debounce(() => {
      if (!tabWidget.current) return;
      setVisibleItems(sections);
      clearTimeout(delay.current);
      delay.current = setTimeout(() => {
        if (!tabWidget.current) return;
        let tabWidgetChildren = Array.from(tabWidget.current.children);
        let currentWidth = 0;
        let tempVisibleItems = [];
        let tempHiddenItems = [];

        for (let i = 0; i < tabWidgetChildren.length; i++) {
          currentWidth += tabWidgetChildren[i].clientWidth + 8;
          if (container.current.clientWidth - currentWidth - 58 >= 0) {
            tempVisibleItems.push(sections[i]);
          } else {
            tempHiddenItems.push(sections[i]);
          }
        }

        setVisibleItems(tempVisibleItems);
        setHiddenItems(tempHiddenItems);
      }, 0);
    }, 100),
    [sections]
  );

  const size = useWindowSize();
  useEffect(() => {
    debounce();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size, sections]);

  return (
    <>
      {sections.length > 0 && (
        <div ref={container} className="w-100">
          <div className="float-end">
            <div className="d-inline-block" ref={tabWidget}>
              {visibleItemRender}
            </div>
            {hiddenItems.length > 0 && (
              <div className="d-inline-block">
                <Dropdown
                  className={`hide-arrow ms-1`}
                  size="md"
                  style={{ zIndex: 1002 }}
                  as={ButtonGroup}
                >
                  <Dropdown.Toggle variant="sensolus-greylight">
                    ...
                  </Dropdown.Toggle>

                  <Dropdown.Menu>{hiddenItemRender}</Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Toolbar;
