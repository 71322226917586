import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import SntRadio from "../../SntRadio/SntRadio";
import useOuterClick from "../useOutsideElement";
import ExcludeLabel from "../ExcludeLabel";
import GeneralFooterFilter from "../GeneralFooterFilter";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import StringUtils from "../../../utils/StringUtils";
import { AdvanceFilter, FilterBody, FilterHeaderStyle } from "../FilterStyle";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import SntCloseSmallIcon from "../../Icons/SntCloseSmallIcon";
import SntArrowDownIcon from "../../Icons/SntArrowDownIcon";
import usePositionFilter from "../usePositionFilter";

const NumericFilter = ({
  descriptor,
  data = {},
  onChange,
  disabled = false,
}) => {
  const language = useSelector((state) => state.language);
  const [isShow, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [errors, setErrors] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [notFilter, setNotFilter] = useState(false);
  const [initialValues, setInitialValues] = useState({
    smallerVal: "1",
    greaterVal: "0",
    betweenGreaterVal: "1",
    betweenSmallerVal: "0",
  });

  let popupStyleRef = useRef({});
  const { popupRef, buttonRef, getPosition } = usePositionFilter();

  const validationSchema = Yup.object({
    smallerVal: Yup.number().required(language.valid_required_key),
    greaterVal: Yup.number().required(language.valid_required_key),
    betweenGreaterVal: Yup.number()
      .required(StringUtils.replaceStr(language.greater_than_key, 0))
      .label("The second value")
      .test({
        test: function (value) {
          const toValue = this.resolve(Yup.ref("betweenSmallerVal"));
          return value >= toValue
            ? true
            : this.createError({
                message: `The second value must be greater than or equal to ${toValue}`,
                path: "betweenGreaterVal", // Fieldname
              });
        },
      }),
    betweenSmallerVal: Yup.number()
      .required(StringUtils.replaceStr(language.greater_than_key, 0))
      .label("The first value"),
  });

  useEffect(() => {
    let filterValue = {};
    if (data) {
      filterValue = data.filterValue;
    }
    let _title = language.all_key;
    let operator = "";
    if (filterValue) {
      operator = filterValue.operator;
      let unit = descriptor.filterTypeSettings.unit || "";
      if (operator === "EQ") {
        _title = "= " + filterValue.eq + " " + unit;
      } else if (operator === "NULL") {
        _title = language.has_no_value_key + unit;
      } else if (operator === "LT") {
        _title = "< " + filterValue.lt + " " + unit;
      } else if (operator === "GT") {
        _title = "> " + filterValue.gt + " " + unit;
      } else if (operator === "BETWEEN") {
        _title = StringUtils.replaceStr(
          language.between_and_key,
          filterValue.lt,
          filterValue.gt + " " + unit
        );
      }

      if (operator) {
        if (operator === "EQ") {
          setInitialValues({ ...initialValues, equalVal: filterValue.eq });
        }

        if (operator === "LT") {
          setInitialValues({ ...initialValues, smallerVal: filterValue.lt });
        }

        if (operator === "GT") {
          setInitialValues({ ...initialValues, greaterVal: filterValue.gt });
        }

        if (operator === "BETWEEN") {
          setInitialValues({
            ...initialValues,
            betweenGreaterVal: filterValue.lt,
            betweenSmallerVal: filterValue.gt,
          });
        }
      }
    }
    setSelectedValue(operator);
    setNotFilter(data.notFilter);

    // setDataState(_dataState);
    setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data.filterValue, descriptor]);

  const getData = useCallback(() => {
    let newData = JSON.parse(JSON.stringify(data));

    if (selectedValue === "EQ") {
      newData.filterValue = {
        operator: "EQ",
        eq: initialValues.equalVal,
      };
    } else if (selectedValue === "NULL") {
      newData.filterValue = {
        operator: "NULL",
      };
    } else if (selectedValue === "LT") {
      newData.filterValue = {
        operator: "LT",
        lt: initialValues.smallerVal,
      };
    } else if (selectedValue === "GT") {
      newData.filterValue = {
        operator: "GT",
        gt: initialValues.greaterVal,
      };
    } else if (selectedValue === "BETWEEN") {
      newData.filterValue = {
        operator: "BETWEEN",
        gt: initialValues.betweenSmallerVal,
        lt: initialValues.betweenGreaterVal,
      };
    }
    newData.notFilter = notFilter;
    return newData;
  }, [data, selectedValue, notFilter, initialValues]);

  const isChanged = useCallback(() => {
    let oldDataFilter = data.filterValue || {};
    let newData = getData();
    let newDataFilter = newData.filterValue || {};

    if (
      oldDataFilter.gt === newDataFilter.gt &&
      oldDataFilter.lt === newDataFilter.lt &&
      oldDataFilter.operator === newDataFilter.operator &&
      (data.notFilter === newData.notFilter ||
        (data.notFilter === undefined && newData.notFilter === false) ||
        (data.notFilter === false && newData.notFilter === undefined))
    )
      return false;
    return true;
  }, [data.filterValue, data.notFilter, getData]);

  const refWrapper = useOuterClick((e) => {
    if (isShow && Object.keys(errors).length === 0) {
      setShow(false);
      if (isChanged()) onChange && onChange(getData());
    }
  });

  const onClickButton = (e) => {
    if (isShow) {
      setShow(false);
      if (isChanged()) onChange && onChange(getData());
    } else {
      popupStyleRef.current = getPosition();
      setShow(true);
    }
  };

  const onAppliedFilter = () => {
    setShow(false);
    if (isChanged()) onChange && onChange(getData());
  };

  const onClearFilter = () => {
    setSelectedValue("");
    setNotFilter(false);
    setShow(false);
    onChange &&
      onChange({
        idx: descriptor._idx,
        data: null,
      });
  };

  const excludeLabel = useMemo(() => {
    return data.notFilter ? <ExcludeLabel /> : null;
  }, [data.notFilter]);

  return (
    <AdvanceFilter ref={refWrapper}>
      <Button
        ref={buttonRef}
        variant="sensolus-greylight"
        title={descriptor.description}
        disabled={disabled}
        onClick={onClickButton}
      >
        {selectedValue && (
          <SntCloseSmallIcon
            className="me-1"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClearFilter();
            }}
          />
        )}
        <span>{descriptor.label || descriptor.description}</span>:{" "}
        <span>{excludeLabel}</span>
        <FilterHeaderStyle>{title}</FilterHeaderStyle>
        <SntArrowDownIcon />
      </Button>
      <FilterBody
        ref={popupRef}
        style={{ display: isShow ? "block" : "none", ...popupStyleRef.current }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(value) => {
            onAppliedFilter();
          }}
        >
          {({ handleSubmit, handleChange, values, errors }) => {
            setTimeout(() => setErrors(errors), 0);
            return (
              <form onSubmit={handleSubmit}>
                <Container>
                  <div className="mb-3 mt-3">
                    <SntRadio
                      value="EQ"
                      selectedValue={selectedValue}
                      onChange={setSelectedValue}
                      label={language.has_equal_value_key}
                    />
                  </div>
                  <Row className="mb-3">
                    <Col className="d-flex justify-content-between">
                      <Form.Control
                        name="equalVal"
                        value={values.equalVal}
                        type="number"
                        className={`${
                          errors.equalVal && selectedValue === "EQ"
                            ? "is-invalid"
                            : "is-valid"
                        } me-2`}
                        style={{ position: "relative" }}
                        disabled={selectedValue === "EQ" ? false : true}
                        onChange={(e) => {
                          handleChange(e);
                          setInitialValues({
                            ...initialValues,
                            equalVal: e.target.value,
                          });
                        }}
                      />
                      <div className="align-self-center">
                        {descriptor.filterTypeSettings.unit}
                      </div>
                    </Col>
                    <Form.Control.Feedback
                      type="invalid"
                      style={{
                        display:
                          errors.smallerVal && selectedValue === "EQ"
                            ? "block"
                            : "none",
                      }}
                    >
                      {errors.smallerVal}
                    </Form.Control.Feedback>
                  </Row>

                  <div className="mb-3 mt-3">
                    <SntRadio
                      value="LT"
                      selectedValue={selectedValue}
                      onChange={setSelectedValue}
                      label={language.less_than_key}
                    />
                  </div>
                  <Row className="mb-3">
                    <Col className="d-flex justify-content-between">
                      <Form.Control
                        name="smallerVal"
                        value={values.smallerVal}
                        type="number"
                        className={`${
                          errors.smallerVal && selectedValue === "LT"
                            ? "is-invalid"
                            : "is-valid"
                        } me-2`}
                        style={{ position: "relative" }}
                        disabled={selectedValue === "LT" ? false : true}
                        onChange={(e) => {
                          handleChange(e);
                          setInitialValues({
                            ...initialValues,
                            smallerVal: e.target.value,
                          });
                        }}
                      />
                      <div className="align-self-center">
                        {descriptor.filterTypeSettings.unit}
                      </div>
                    </Col>
                    <Form.Control.Feedback
                      type="invalid"
                      style={{
                        display:
                          errors.smallerVal && selectedValue === "LT"
                            ? "block"
                            : "none",
                      }}
                    >
                      {errors.smallerVal}
                    </Form.Control.Feedback>
                  </Row>

                  <div className="mb-3">
                    <SntRadio
                      value="BETWEEN"
                      selectedValue={selectedValue}
                      onChange={setSelectedValue}
                      label={language.between_key}
                    />
                  </div>
                  <Row className="mb-3">
                    <Col className="d-flex justify-content-between">
                      <Form.Control
                        name="betweenSmallerVal"
                        value={values.betweenSmallerVal}
                        type="number"
                        className={`tmp me-2 ${
                          (errors.betweenGreaterVal ||
                            errors.betweenSmallerVal) &&
                          selectedValue === "BETWEEN"
                            ? "is-invalid"
                            : "is-valid"
                        }`}
                        style={{ position: "relative" }}
                        disabled={selectedValue === "BETWEEN" ? false : true}
                        onChange={(e) => {
                          handleChange(e);
                          setInitialValues({
                            ...initialValues,
                            betweenSmallerVal: e.target.value,
                          });
                        }}
                      />
                      <div className="align-self-center me-2">-</div>
                      <Form.Control
                        name="betweenGreaterVal"
                        value={values.betweenGreaterVal}
                        type="number"
                        className={`tmp me-2 ${
                          (errors.betweenGreaterVal ||
                            errors.betweenSmallerVal) &&
                          selectedValue === "BETWEEN"
                            ? "is-invalid"
                            : "is-valid"
                        }`}
                        style={{ position: "relative" }}
                        disabled={selectedValue === "BETWEEN" ? false : true}
                        onChange={(e) => {
                          handleChange(e);
                          setInitialValues({
                            ...initialValues,
                            betweenGreaterVal: e.target.value,
                          });
                        }}
                      />
                      <div className="align-self-center">
                        {descriptor.filterTypeSettings.unit}
                      </div>
                    </Col>
                    <Form.Control.Feedback
                      type="invalid"
                      style={{
                        display:
                          (errors.betweenGreaterVal ||
                            errors.betweenSmallerVal) &&
                          selectedValue === "BETWEEN"
                            ? "block"
                            : "none",
                      }}
                    >
                      {errors.betweenSmallerVal || errors.betweenGreaterVal}
                    </Form.Control.Feedback>
                  </Row>

                  <div className="mb-3">
                    <SntRadio
                      value="GT"
                      selectedValue={selectedValue}
                      onChange={setSelectedValue}
                      label={language.more_than_key}
                    />
                  </div>
                  <Row className="mb-3">
                    <Col className="d-flex justify-content-between">
                      <Form.Control
                        name="greaterVal"
                        value={values.greaterVal}
                        type="number"
                        className={`tmp me-2 ${
                          errors.greaterVal && selectedValue === "GT"
                            ? "is-invalid"
                            : "is-valid"
                        }`}
                        style={{ position: "relative" }}
                        disabled={selectedValue === "GT" ? false : true}
                        onChange={(e) => {
                          handleChange(e);
                          setInitialValues({
                            ...initialValues,
                            greaterVal: e.target.value,
                          });
                        }}
                      />
                      <div className="align-self-center">
                        {descriptor.filterTypeSettings.unit}
                      </div>
                    </Col>
                    <Form.Control.Feedback
                      type="invalid"
                      style={{
                        display:
                          errors.greaterVal && selectedValue === "GT"
                            ? "block"
                            : "none",
                      }}
                    >
                      {errors.greaterVal}
                    </Form.Control.Feedback>
                  </Row>
                  <div className="mb-3 mt-3">
                    <SntRadio
                      value="NULL"
                      selectedValue={selectedValue}
                      onChange={setSelectedValue}
                      label={language.has_no_value_key}
                    />
                  </div>
                </Container>
                <GeneralFooterFilter
                  onClearFilter={(e) => onClearFilter(e)}
                  onAppliedFilter={(e) => {
                    if (Object.keys(errors).length === 0) {
                      handleSubmit();
                    }
                  }}
                  isNotFilter={notFilter}
                  onCheckNotFilter={(e) => {
                    setNotFilter(e);
                  }}
                  disableCheckBox={!selectedValue}
                />
              </form>
            );
          }}
        </Formik>
      </FilterBody>
    </AdvanceFilter>
  );
};

export default NumericFilter;
