import { useEffect, useMemo, useRef, useState } from "react";

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import AdminLayout from "../../containers/AdminLayout/AdminLayout";
import DeleteTag from "../DialogTag/DeleteTag";
import CanNotDeleteTag from "../DialogTag/CanNotDeleteTag";
import ReactHtmlParser from "react-html-parser";
import SntDialog from "../../components/SntDialog/SntDialog";
import { useOrg } from "../../contexts/OrgContext";
import { AssetTag } from "../../components/ReactBootstrap/TagMore";
import SntActionButton from "../../components/ReactBootstrap/SntActionButton";
import SntPencilIcon from "../../components/Icons/SntPencilIcon";
import SntDeleteIcon from "../../components/Icons/SntDeleteIcon";
import SntLink from "../../components/ReactBootstrap/SntLink";
import SntAdvancedSearchTableViewCard from "../../components/SntTableViewCard/SntAdvancedSearchTableViewCard";
import partnerTagClient from "@/apis/partnerTagClient";

const PAGE_NAME = "PARTNER_TAG";
const SHARED_KEY = "PARTNER_TAG_OVERVIEW";
const DEFAULT_OPTION = {
  pageName: PAGE_NAME,
  queryObjectType: "PARTNER_TAG",
  filtersVisibleAtInit: ["name"],
  optionsSettings: {},
};

const PartnerTagList = ({
  reload,
  addFilter,
  isEmbeddedComponent = false,
  isHomeMenu,
}) => {
  //redux state
  const { orgId, updateOrg } = useOrg();
  const language = useSelector((state) => state.language);
  const loginInfo = useSelector((state) => state.user);
  const { permissionsMap, organisation } = loginInfo;
  const table = useRef();
  const [itemSelected, setItemSelected] = useState(null);
  const history = useHistory();
  const [showDelete, setShowDelete] = useState(false);
  const [showWarningDelete, setShowWarningDelete] = useState(false);
  const [numOfDeviceLinked, setNumOfDeviceLinked] = useState(0);
  const [linkedList, setLinkedList] = useState();
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (orgId == null) {
      updateOrg(loginInfo.organisation.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  const onNumAssetClick = (e, _id) => {
    e.preventDefault();
    let _query = {
      categoryId: "assetInfo",
      filterKey: "partnerTags",
      filterType: "MULTI_SELECT_CHECKBOX",
      filterValue: {
        includeEmpty: false,
        selectedValues: [_id],
      },
      searchType: "STATIC",
    };

    sessionStorage.setItem("EXTEND_GEOZONE_FITLER", JSON.stringify([_query]));
    history.push("tracked_assets?org=" + loginInfo.organisation.id);
  };

  const allColumns = useMemo(() => {
    const allColumns = [
      {
        key: "name",
        title: language.name_key,
        Cell: ({ cell, value }) => {
          return (
            <AssetTag
              role="button"
              onClick={(event) => {
                event.preventDefault();
                history.push(
                  `/partner_tag_manage?org=${orgId}&type=view&id=${cell.row.original.id}`
                );
              }}
            >
              {value}
            </AssetTag>
          );
        },
      },
      {
        key: "numAssets",
        title: language.number_of_assets_key,
        Cell: ({ cell, value }) => {
          return value ? (
            <SntLink onClick={(e) => onNumAssetClick(e, cell.row.original.id)}>
              {value}
            </SntLink>
          ) : (
            ""
          );
        },
      },
    ];

    return allColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultColumnInit = useMemo(() => {
    return ["name", "numAssets", "actions"];
  }, []);

  const extraCols = useMemo(() => {
    return [
      {
        key: "actions",
        title: language.actions_key,
        immovable: true,
        disableSortBy: true,
        canResize: false,
        Cell: ({ cell }) => {
          let full = cell.row.original;
          return (
            <>
              <SntActionButton
                title={language.edit_key}
                to={`/partner_tag_manage?org=${orgId}&type=edit&id=${cell.row.original.id}`}
              >
                <SntPencilIcon />
              </SntActionButton>
              <SntActionButton
                title={language.delete_key}
                onClick={(e) => onDeleteClicked(e, full)}
              >
                <SntDeleteIcon />
              </SntActionButton>
            </>
          );
        },
      },
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeleteClicked = (el, item) => {
    el.preventDefault();
    // prevalidate
    partnerTagClient
      .checkTag(item.id)
      .then((data) => {
        // count number of asset tag
        setNumOfDeviceLinked(data.data || 0);
        setShowDelete(true);
        setItemSelected(item);
      })
      .catch((e) => {
        setLinkedList(e.response.data);
        setShowWarningDelete(true);
      });
  };

  const onDeleteSelectedItem = () => {
    if (itemSelected) {
      partnerTagClient
        .delete(itemSelected.id)
        .then(() => {
          setShowDelete(false);
          setItemSelected(null);
          table.current.refresh();
        })
        .catch((err) => {
          console.log(err);
          setShowDelete(false);
          if (err && err.response && err.response.data) {
            setMessage(err.response.data.message);
            setShowNotification(true);
          }
        });
    }
  };

  const onDataRequestChange = (data, callback) => {
    const { params, dataOptions } = data;
    if (table.current) {
      table.current.setLoading(true);
      partnerTagClient.search(params, orgId).then(
        ({ data }) => {
          if (table.current) {
            table.current.updateDataTable({
              data: data.data,
              pageCount: data.countByFilter,
              totalItem: {
                countAll: data.countAll,
                countByFilter: data.countByFilter,
              },
            });
            table.current.setLoading(false);

            const dataCallback = table.current.getDataCallBack(
              params,
              dataOptions,
              data
            );
            callback(dataCallback[0], dataCallback[1]);
          }
        },
        (err) => {
          table.current.setLoading(false);
        }
      );
    }
  };

  const preventCols = [];
  return (
    <>
      <SntDialog
        isShow={showNotification}
        onSave={() => {
          setShowNotification(false);
        }}
        title={language.remove_tag}
        saveTxt={language.close_key}
        isShowCloseButton={false}
      >
        {() => <div>{ReactHtmlParser(message)}</div>}
      </SntDialog>
      <DeleteTag
        numLinkedObject={numOfDeviceLinked}
        objectType={language.assets_lower_key}
        isShow={showDelete}
        onDelete={onDeleteSelectedItem}
        onClose={() => setShowDelete(false)}
      />

      <CanNotDeleteTag
        linkedList={linkedList}
        objectType={language.assets_lower_key}
        isShow={showWarningDelete}
        onClose={() => setShowWarningDelete(false)}
      />
      <AdminLayout
        showPermissionWarning
        browserTabTitle={isEmbeddedComponent ? "" : language.partner_tags}
        headerTitle={isEmbeddedComponent ? "" : language.partner_tags}
        reload={reload}
        pageName={PAGE_NAME}
        sharedKey={SHARED_KEY}
        defaultOption={DEFAULT_OPTION}
        isAdvanceFilter={true}
        isShowBackHistory={!isEmbeddedComponent}
        isHideTheSearchUI={isEmbeddedComponent} // hide the advanced filter UI because of the design
        newButton={{
          isShow:
            !isEmbeddedComponent &&
            (organisation.organisationType === "SYSTEM" ||
              organisation.organisationType === "PARTNER") &&
            permissionsMap["DEVICE_UPDATE"],
          link: `/partner_tag_manage?type=add`,
          label: language.new_tag_key,
        }}
        restrictedFilters={["organizations"]} //use org filter in the Sidebar
        totalLabel={language.feature_max_users_unit}
        body={({ isDisabled, dataOptions, onChangeChildren }) =>
          dataOptions && (
            <SntAdvancedSearchTableViewCard
              shared={(child) => (table.current = child)}
              allColumns={allColumns}
              extraCols={isHomeMenu ? [] : extraCols}
              visibleCols={defaultColumnInit}
              preventCols={preventCols}
              disabled={isDisabled}
              onChange={(data) => {
                onDataRequestChange(data, onChangeChildren);
              }}
              dataOptions={dataOptions}
              addFilter={addFilter}
              key={orgId || loginInfo.organisation.id}
              orgId={orgId || loginInfo.organisation.id}
              useOrgInSidebar={true}
              categoryId={"partnerTagInfo"}
            />
          )
        }
      ></AdminLayout>
    </>
  );
};

export default PartnerTagList;
